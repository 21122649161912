
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
import Chart from "chart.js/auto";
import _ from "lodash";
// import AssessmentsTable from "@/components/Table/AssessmentsAssessments.vue";
import CandidateTable from "@/components/Table/Candidate.vue";
import {
  valueAndTraitsData,
  acceptableData,
} from "@/views/Assessments/mock_data_modal_value_and_traits";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import ColorPicker from "@/components/Input/ColorPicker.vue";

import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

export default defineComponent({
  components: {
    // AssessmentsTable,
    CandidateTable,
    EditorContent,
    ColorPicker,
  },
  title() {
    let name = "My Assessments";
    if (this.formTemplate) {
      name = (this as any).formTemplate.form_name;
    }
    return `${name} | My Culture`;
  },
  data() {
    return {
      advancedInviteStatus: false,
      inviteButtonLoading: false,
      inviteBulkButtonLoading: false,
      createdBy: "" as any,
      assessmentsID: "",
      mutipleBulkEmail: "",
      inputBulkEmail: "",
      mutipleBulkValidteEmail: [""],
      form: {
        firstname: "",
        lastname: "",
        email: "",
      },
      formValidate: {
        email: false,
      },
      advancedForm: {
        subject: "You've been invited to an assessment",
        emailBody: null,
        emailSender: "MyCulture <no-reply@myculture.info>",
        recipients: [
          {
            firstname: "",
            lastname: "",
            email: "",
          },
          {
            firstname: "",
            lastname: "",
            email: "",
          },
          {
            firstname: "",
            lastname: "",
            email: "",
          },
          {
            firstname: "",
            lastname: "",
            email: "",
          },
          {
            firstname: "",
            lastname: "",
            email: "",
          },
          {
            firstname: "",
            lastname: "",
            email: "",
          },
        ],
      },
      inviteExpendStatus: false,
      inviteExpendHeight: 0,
      anlyticsExpendStatus: false,
      anlyticsExpendHeight: 0,
      valueAndTraitsModalStatus: false,
      valueAndTraitsTapSelected: "Values",
      valueAndTraitsMockData: [
        {
          name: "",
          label_en: "",
          id: "",
          percentage: 0,
          value: [
            {
              name: "",
              label_en: "",
              id: "",
              percentage: 0,
              candidate: [
                {
                  displayName: "",
                  name: "",
                  id: "",
                  color: "",
                },
              ],
            },
          ],
        },
      ],
      cultureProfileModalStatus: false,
      cultureProfileTabSelected: "",
      cultureProfileSelected: {
        id: "",
        label: "",
        value: "",
      },
      humanSkillsModalStatus: false,
      humanSkillsTabSelected: "",
      humanSkillsSelected: {
        id: "",
        label: "",
        value: "",
      },
      logicTestModalStatus: false,
      logicTestTabSelected: "",
      logicTestSelected: {
        id: "",
        label: "",
        value: "",
      },
      acceptableBehaviorsModalStatus: false,
      acceptableMockData: [
        {
          name: "",
          valuePercentage: 0,
          question: "",
          candidateValue: [
            {
              displayName: "",
              value: "",
            },
          ],
        },
      ],
      radarChartCompanyData: [30, 22, 41, 36],
      radarChartVal: {},
      culturePreferenceRenderChart: false,
      culturePreferenceRenderChartValue: 1,
      culturePreferenceRenderChartFilter: [""],

      // Human Skills
      humanSkillsRadarChartVal: {},
      humanSkillsRenderChart: false,
      humanSkillsRenderChartValue: 1,
      humanSkillsRenderChartFilter: [""],

      // Logic Test
      logicTestRadarChartVal: {},
      logicTestRenderChart: false,
      logicTestRenderChartValue: 1,
      logicTestRenderChartFilter: [""],

      // valueAndTraitsModalData: {},
      analyticsLoadingStatus: false,
      analyticsLoadedStatus: false,

      // @see https://trello.com/c/ByQq6vUm/1459-assessments-as-a-manager-i-want-to-group-analytics-for-culture-profile-so-that-i-easily-identify-areas-of-strengths-and-opportun
      avialableColors: [
        "#35e87c",
        "#407bb2",
        "#7867ce",
        "#f7e51d",
        "#d8658e",
        "#003f5c",
        "#58508d",
        "#bc5090",
        "#ff6361",
        "#ffa600",
        "#ff595e",
        "#ffca3a",
        "#8ac926",
        "#0ea5e9",
        "#6a4c93",
        "#00202e",
        "#003f5c",
        "#2c4875",
        "#8a508f",
        "#bc5090",
        "#ff6361",
        "#ff8531",
        "#ffa600",
        "#ffd380",
      ],
      // @see src\components\MainCardResults.vue
      tooltipOncultureStatus: true,
      tooltipOncultureLabelPrefix: "",
      tooltipOncultureLabel: {
        collaborate: "",
        create: "",
        compete: "",
        control: "",
      },
      lastPage: "",

      guidedTourCreateDone: false,
      guidedTourInviteDone: false,

      // Singup
      signupModalStatus: false,
      verifyModalStatus: false,
      signupForm: {
        email: "",
        lang: "en",
        language: "en",
        firstname: "",
        lastname: "",
        jobTitle: "",
        companyName: "",
        typeOfOrgSelected: "",
        numberOfEmployee: "",

        // Compatible with previous version. Please be careful when changing.
        firstName: "",
        lastName: "",

        // Branding
        imageFile: "",
        companyUploadedFile: new File([], ""),
        companyUploadedFileDataUrl: "",
      } as any,
      validateSignupForm: {
        email: false,
        language: false,
        firstname: false,
        lastname: false,
        jobTitle: false,
        companyName: false,
        typeOfOrgSelected: false,
        numberOfEmployee: false,
      },
      email: "",
      emailWarningText: "",
      validateEmail: false,
      selectedFile: null,
      previewUrl: "",
      hexCode: "",
      validateFormStatus: false,
      signupStep: 1,
    };
  },
  computed: {
    isGuest(): boolean {
      return _.get(this.$store.getters["auth/user"], "isGuest", false);
    },
    isImage(): any {
      return (
        this.selectedFile && (this.selectedFile as any).type.includes("image")
      );
    },
    user(): any {
      return this.$store.getters["auth/user"];
    },
    allQuestions(): any {
      return this.$store.getters["assessmentSection/allQuestions"] || [];
    },
    completedCandidates(): any {
      const candidates = _.filter(
        this.$store.getters["assessment/candidates"] || [],
        {
          status: "COMPLETED",
        }
      );
      return candidates;
    },
    valueAndTraitsModalData(): any {
      const analytics = this.$store.getters["assessment/analytics"] || {};
      let result: any = {};
      const isValuesSelected = this.valueAndTraitsTapSelected == "Values";
      if (isValuesSelected) {
        if (
          analytics.values_and_traits &&
          analytics.values_and_traits.defining_your_values
        ) {
          result = analytics.values_and_traits.defining_your_values;
        }
      } else {
        if (
          analytics.values_and_traits &&
          analytics.values_and_traits.identifying_key_traits
        ) {
          result = analytics.values_and_traits.identifying_key_traits;
        }
      }

      if (this.isV2) {
        const questionsMap: any = {};
        for (const i in result) {
          for (const j in result[i].value) {
            const question = result[i].value[j];
            questionsMap[question.id] = question;
          }
        }

        const keys: any = {
          core_values: 4,
          permission_to_play: 4,
          moderately_important: 2,
          non_essential: 0,
        };
        const results = [];
        for (const key in keys) {
          const priority = keys[key];

          const values = _.get(
            this.formTemplate,
            "values_and_traits_v_2.values." + key
          );
          if (!values) continue;

          const newValues = [];
          for (const qid in values) {
            if (Object.prototype.hasOwnProperty.call(questionsMap, qid)) {
              newValues.push(questionsMap[qid]);
            }
          }

          results.push({
            id: key,
            label_en: (this as any).$i18n.t("setup.valueAndTraits." + key),
            perentage: 0,
            priority: priority,
            value: newValues,
          });
        }

        // I think I understand it now krub. Yes, we should display the values even if the user hasn't selected it krub.
        // So the other non-essential values would be displayed as rows but remain unselected in the view
        //
        // Got it krub and use this rule for other krub such as "core values" and "permission to play" krub?
        // Yes please krub
        const doDisplayUnselectedValues = true;
        if (doDisplayUnselectedValues) {
          const valuesMap = _.get(
            this.formTemplate,
            "values_and_traits_v_2.values"
          );
          // console.log(valuesMap, "valuesMap");
          if (valuesMap) {
            const allQuestions = this.allQuestions;
            const completedCandidates = this.completedCandidates;
            let users: any = [];
            _.each(completedCandidates, (candidate: any) => {
              const displayName = candidate.display_name;
              const score =
                _.get(
                  candidate,
                  "summaries.values_and_traits.defining_your_values"
                ) || 0;
              const user: any = {
                name: displayName,
                displayName: displayName,
                shortName:
                  String(candidate.firstname)[0].toUpperCase() +
                  String(candidate.lastname)[0].toUpperCase(),
                id: candidate._id,
                color: "",
                defining_your_values: score,
              };
              users.push(user);
            });

            users = _.sortBy(users, "defining_your_values").reverse();

            for (const groupName in valuesMap) {
              if (!valuesMap[groupName]) continue;
              const allQuestionIds = Object.keys(valuesMap[groupName]);
              let row = _.find(results, { id: groupName });
              const qids = [];
              if (row && row.value) {
                for (const i in row.value) {
                  const qid = row.value[i].id;
                  if (!qid) continue;
                  qids.push(qid);
                }
                const diffQuestionIds = _.difference(allQuestionIds, qids);
                if (diffQuestionIds.length) {
                  for (const j in diffQuestionIds) {
                    const diffQuestionId = diffQuestionIds[j];
                    const diffQuestion = allQuestions[diffQuestionId];
                    row.value.push({
                      name: diffQuestion.title,
                      label_en: diffQuestion.title,
                      id: diffQuestionId,
                      percentage: 0,
                      candidate: users,
                    });
                  }
                }
              }
            }
          }
        }
        result = results;
      }
      return result;
    },
    acceptableData(): any[] {
      const analytics = this.$store.getters["assessment/analytics"] || {};
      let acceptableData = _.get(analytics, "acceptable_behaviors") || [];
      acceptableData = _.sortBy(acceptableData, ["order"]);
      return acceptableData;
    },
    radarChartCandidateData(): any[] {
      const analytics = this.$store.getters["assessment/analytics"] || {};
      let items: any[] = [];
      if (Object.prototype.hasOwnProperty.call(analytics, "oaic_archetypes")) {
        const summary = analytics.oaic_archetypes || {};
        if (this.cultureProfileSelected && this.cultureProfileSelected.id) {
          const id = this.cultureProfileSelected.id;
          const rows = summary[id] || [];
          // const colors = ["#30CCB4", "#9CCC30", "#2AEAF2", "#C452F7"];
          const colors = this.avialableColors;
          for (let i = 0; i < rows.length; i++) {
            const idx = i % colors.length;
            const borderColor = colors[idx];
            let item = {
              name: rows[i].user.displayName,
              id: rows[i].user.id,
              data: rows[i].dataset,
              score: rows[i].score,
              pointStyle: "line",
              color: borderColor,
            };
            if (i === 0) {
              item = {
                ...item,
                ...{ color: this.companyColor, borderDash: [3, 1] },
              };
            }
            items.push(item);
          }
        }
      }
      return items;
    },
    radarChartHumanSkillsCandidateData(): any[] {
      const analytics = this.$store.getters["assessment/analytics"] || {};
      let items: any[] = [];
      if (Object.prototype.hasOwnProperty.call(analytics, "human_skills")) {
        const summary = analytics.human_skills || {};
        if (this.humanSkillsSelected && this.humanSkillsSelected.id) {
          const id = this.humanSkillsSelected.id;
          const rows = summary[id] || [];
          const colors = this.avialableColors;
          for (let i = 0; i < rows.length; i++) {
            const idx = i % colors.length;
            const borderColor = colors[idx];
            let item = {
              name: rows[i].user.displayName,
              id: rows[i].user.id,
              data: rows[i].dataset,
              score: rows[i].score,
              pointStyle: "line",
              color: borderColor,
            };
            if (i === 0) {
              item = {
                ...item,
                ...{ color: this.companyColor, borderDash: [3, 1] },
              };
            }
            items.push(item);
          }
        }
      }
      return items;
    },
    radarChartLogicTestCandidateData(): any[] {
      const analytics = this.$store.getters["assessment/analytics"] || {};
      console.log("analytics", analytics);

      let items: any[] = [];
      if (Object.prototype.hasOwnProperty.call(analytics, "logic_test")) {
        const summary = analytics.logic_test || {};
        console.log("summaryxl", summary);
        console.log("logicTestSelected", this.logicTestSelected);
        if (summary) {
          for (let i = 0; i < summary.length; i++) {
            const colors = this.avialableColors;
            const idx = i % colors.length;
            const borderColor = colors[idx];

            let item = {
              name: summary[i].user.displayName,
              id: summary[i].user.id,
              data: summary[i].dataset,
              score: summary[i].score,
              pointStyle: "line",
              color: borderColor,
            };

            if (i === 0) {
              item = {
                ...item,
                ...{ color: this.companyColor, borderDash: [3, 1] },
              };
            }
            items.push(item);
          }
        }
      }
      return items;
    },
    companyName(): string {
      if (this.radarChartCandidateData && this.radarChartCandidateData.length) {
        return this.radarChartCandidateData[0].name;
      }
      return "";
    },
    publicLink(): string {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const routerPath = "assessment-test";
      // @see src\views\AssessmentTest.vue
      const publicLink = `${baseUrl}/${routerPath}/${formTemplate.routeId}`;
      return publicLink;
    },
    privateLink(): string {
      // return "happily.ai/company/a8dnr55555";
      // assessment-test

      // @todo validate form
      const email = this.form.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (!email || !this.form.firstname || !this.form.lastname) {
        return "";
      }

      const id = this.$router.currentRoute.value.query.id;
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const routerPath = "assessment-test";

      const parmas = new URLSearchParams();
      parmas.set("email", this.form.email);
      parmas.set("firstname", this.form.firstname);
      parmas.set("lastname", this.form.lastname);
      const uuid = btoa(encodeURIComponent(parmas.toString()));

      // @see src\views\AssessmentTest.vue
      const privateUrl = `${baseUrl}/${routerPath}/${id}/${uuid}`;
      return privateUrl;
    },
    cadidateData() {
      return this.$store.getters["assessment/candidates"];
    },
    data() {
      return this.$store.getters["assessment/data"];
    },
    formTemplate() {
      return this.$store.getters["assessment/formTemplate"];
    },
    isV2(): boolean {
      return this.formTemplate
        ? Object.prototype.hasOwnProperty.call(
            this.formTemplate,
            "values_and_traits_v_2"
          )
        : false;
    },
    sections() {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const sections = formTemplate.sections || [];

      return sections.length;
    },
    extratime() {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      const sections = formTemplate.sections || [];
      return extratime * sections.length;
    },
    sectionTimeTotal() {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const sections = formTemplate.sections || [];
      const extratime =
        (Number(formTemplate.extra_time) || 0) * sections.length;
      let sectionTimeTotal = sections.length * 5 + extratime;

      if (sections.indexOf("human_skills") != -1) {
        sectionTimeTotal += 5;
      }

      return sectionTimeTotal;
    },
    loading() {
      return (
        this.$store.getters["assessment/loading"] ||
        this.$store.getters["assessmentTemplate/loading"]
      );
    },
    companyColor() {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    scoreSummaryLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          let colorScore = this.checkScoreColor(candidate[i].score);
          const objects = {
            name: candidate[i].display_name,
            percentageOfBar: candidate[i].score,
            value: candidate[i].score,
            color: colorScore,
          };
          itemsSummarycandidate.push(objects);
        }
      }
      return itemsSummarycandidate;
    },
    valueAndTraitsLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          let summaries: any = _.get(
            candidate[i],
            "summaries.values_and_traits"
          );
          if (summaries) {
            if (this.isV2) {
              summaries.overall = summaries.defining_your_values;
            }
            let colorScore = this.checkScoreColor(summaries.overall);
            const objects = {
              name: candidate[i].display_name,
              percentageOfBar: summaries.overall,
              value: summaries.overall,
              color: colorScore,
            };
            itemsSummarycandidate.push(objects);
          }
        }
      }
      return itemsSummarycandidate;
    },
    valueAndTraitsDataDetail() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      let itemCalculatorValue: any[] = [];
      let itemCalculatorTraits: any[] = [];
      let summaries: any;
      let resultValue = 0;
      let resultTraits = 0;
      // let colorScore = "";
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          if (!candidate[i].summaries) continue;
          summaries = _.get(candidate[i], "summaries.values_and_traits");
          if (summaries) {
            itemCalculatorValue.push(summaries.defining_your_values);
            resultValue =
              _.sum(itemCalculatorValue) / itemCalculatorValue.length;
            itemCalculatorTraits.push(summaries.identifying_key_traits);
            resultTraits =
              _.sum(itemCalculatorTraits) / itemCalculatorTraits.length;
            // colorScore = this.checkScoreColor(summaries.overall);
          }
        }
      }
      itemsSummarycandidate = [
        {
          label: helpers.t("assessmentResults.Values"),
          value: Math.round(resultValue),
          color: this.checkScoreColor(Math.round(resultValue)),
        },
        {
          label: helpers.t("assessmentResults.Traits"),
          value: Math.round(resultTraits),
          color: this.checkScoreColor(Math.round(resultTraits)),
        },
      ];

      return itemsSummarycandidate;
    },
    cultureProfileLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          let colorScore = "";
          if (!candidate[i].summaries) continue;
          let summaries = _.get(candidate[i], "summaries.oaic_archetypes");
          if (summaries) {
            colorScore = this.checkScoreColor(summaries.overall);
            const objects = {
              name: candidate[i].display_name,
              percentageOfBar: summaries.overall,
              value: summaries.overall,
              color: colorScore,
            };
            itemsSummarycandidate.push(objects);
          }
        }
      }
      return itemsSummarycandidate;
    },
    cultureProfileDataDetail() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      let itemsDominant: any[] = [];
      let itemsOrganiztionGlue: any[] = [];
      let itemsOrganiztionLeader: any[] = [];
      let itemsStrategic: any[] = [];
      let itemsManagement: any[] = [];
      let itemsCriteria: any[] = [];
      let summaries: any;
      let resultDominant,
        resultOrganiztionGlue,
        resultOrganiztionLeader,
        resultStrategic,
        resultManagement,
        resultCriteria = 0;
      let colorScoreD,
        colorScoreOG,
        colorScoreOL,
        colorScoreS,
        colorScoreM,
        colorScoreC = "";
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          summaries = _.get(candidate[i], "summaries.oaic_archetypes");
          if (summaries) {
            itemsDominant.push(summaries.diff.dominant_characteristics);
            itemsOrganiztionGlue.push(summaries.diff.organization_glue);
            itemsOrganiztionLeader.push(
              summaries.diff.organizational_leadership
            );
            itemsStrategic.push(summaries.diff.strategic_emphases);
            itemsManagement.push(summaries.diff.management_of_employees);
            itemsCriteria.push(summaries.diff.criteria_of_success);

            resultDominant = _.sum(itemsDominant) / itemsDominant.length;
            resultOrganiztionGlue =
              _.sum(itemsOrganiztionGlue) / itemsOrganiztionGlue.length;
            resultOrganiztionLeader =
              _.sum(itemsOrganiztionLeader) / itemsOrganiztionLeader.length;
            resultStrategic = _.sum(itemsStrategic) / itemsStrategic.length;
            resultManagement = _.sum(itemsManagement) / itemsManagement.length;
            resultCriteria = _.sum(itemsCriteria) / itemsCriteria.length;
            colorScoreD = this.checkScoreColor(resultDominant);
            colorScoreOG = this.checkScoreColor(resultOrganiztionGlue);
            colorScoreOL = this.checkScoreColor(resultOrganiztionLeader);
            colorScoreS = this.checkScoreColor(resultStrategic);
            colorScoreM = this.checkScoreColor(resultManagement);
            colorScoreC = this.checkScoreColor(resultCriteria);
          }
        }
      }

      const oaicArchetypes =
        this.$store.getters["assessmentSection/oaicArchetypes"] || {};

      // @see src\store\modules\assessment.ts
      // transformOaicArchetypes(), tooltipOncultureLabel
      const locale = "en";
      const translate = (obj: any, attr: string, lang: string) => {
        // @note hold all this issue waiting all translation
        // const lang = (this as any).$i18n.getLocale(0) || "en";
        let label = "";
        if (_.isObject(obj)) {
          if (lang != "en") {
            attr = attr + "_" + lang;
          }
          if (Object.prototype.hasOwnProperty.call(obj, attr)) {
            label = (obj as any)[attr];
          }
        }
        return label;
      };
      const getTooltip = (name: string) => {
        const path = "scenarios." + _.camelCase(name);
        const scenario = _.get(oaicArchetypes, path);
        let tooltipOncultureLabel = {
          collaborate: "",
          create: "",
          compete: "",
          control: "",
        };
        if (scenario && scenario.questions) {
          tooltipOncultureLabel = {
            collaborate: _.filter(scenario.questions, {
              profile: "clan",
            }).map((v) => translate(v, "admin_title", locale))[0],
            create: _.filter(scenario.questions, {
              profile: "create",
            }).map((v) => translate(v, "admin_title", locale))[0],
            compete: _.filter(scenario.questions, {
              profile: "complete",
            }).map((v) => translate(v, "admin_title", locale))[0],
            control: _.filter(scenario.questions, {
              profile: "control",
            }).map((v) => translate(v, "admin_title", locale))[0],
          };
        }
        return {
          tooltipOncultureLabelPrefix: scenario
            ? scenario.admin_description
            : "",
          tooltipOncultureLabel: tooltipOncultureLabel,
        };
      };

      itemsSummarycandidate = [
        {
          id: "dominant_characteristics",
          label: helpers.t("assessmentResults.Dominant_Characteristics"),
          value: Math.round(Number(resultDominant)) || 0,
          color: colorScoreD,
          tooltip: getTooltip("dominant_characteristics"),
        },
        {
          id: "organization_glue",
          label: helpers.t("assessmentResults.Organizational_Glue"),
          value: Math.round(Number(resultOrganiztionGlue)) || 0,
          color: colorScoreOG,
          tooltip: getTooltip("organization_glue"),
        },
        {
          id: "organizational_leadership",
          label: helpers.t("assessmentResults.Organizational_Leadership"),
          value: Math.round(Number(resultOrganiztionLeader)) || 0,
          color: colorScoreOL,
          tooltip: getTooltip("organizational_leadership"),
        },
        {
          id: "strategic_emphases",
          label: helpers.t("assessmentResults.Strategic_Emphases"),
          value: Math.round(Number(resultStrategic)) || 0,
          color: colorScoreS,
          tooltip: getTooltip("strategic_emphases"),
        },
        {
          id: "management_of_employees",
          label: helpers.t("assessmentResults.Management_of_Employees"),
          value: Math.round(Number(resultManagement)) || 0,
          color: colorScoreM,
          tooltip: getTooltip("management_of_employees"),
        },
        {
          id: "criteria_of_success",
          label: helpers.t("assessmentResults.Criteria_of_Success"),
          value: Math.round(Number(resultCriteria)) || 0,
          color: colorScoreC,
          tooltip: getTooltip("criteria_of_success"),
        },
      ];
      return itemsSummarycandidate;
    },
    humanSkillsLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          let colorScore = "";
          if (!candidate[i].summaries) continue;
          let summaries = _.get(candidate[i], "summaries.human_skills");
          if (summaries) {
            colorScore = this.checkScoreColor(summaries.overall);
            const objects = {
              name: candidate[i].display_name,
              percentageOfBar: summaries.overall,
              value: summaries.overall,
              color: colorScore,
            };
            itemsSummarycandidate.push(objects);
          }
        }
      }
      return itemsSummarycandidate;
    },
    humanSkillsDataDetail() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      let summaries = [];

      const result: any = {};
      let total = 0;
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          summaries = _.get(
            candidate[i],
            "summaries.human_skills.human_skills"
          );
          if (!summaries) continue;
          for (const j in summaries) {
            if (!result[j]) {
              result[j] = {
                score: 0,
              };
            }
            result[j].score += Number(summaries[j].score || 0);
          }
          total++;
        }
      }
      for (const i in result) {
        result[i].score = total ? result[i].score / total : 0;
        result[i].color = this.checkScoreColor(result[i].score);
      }

      itemsSummarycandidate = [
        {
          id: "written_communication",
          label: helpers.t("assessmentResults.Written_Communication"),
          value: Math.round(Number(result.written_communication.score)) || 0,
          color: result.written_communication.color,
          tooltip: "",
        },
        {
          id: "empathy",
          label: helpers.t("assessmentResults.Empathy"),
          value: Math.round(Number(result.empathy.score)) || 0,
          color: result.empathy.color,
          tooltip: "",
        },
        {
          id: "situational_awareness",
          label: helpers.t("assessmentResults.Situational_Awareness"),
          value: Math.round(Number(result.situational_awareness.score)) || 0,
          color: result.situational_awareness.color,
          tooltip: "",
        },
        {
          id: "critical_thinking",
          label: helpers.t("assessmentResults.Critical_Thinking"),
          value: Math.round(Number(result.critical_thinking.score)) || 0,
          color: result.critical_thinking.color,
          tooltip: "",
        },
      ];
      return itemsSummarycandidate;
    },

    logicTestLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          let colorScore = "";
          if (!candidate[i].summaries) continue;
          let summaries = _.get(candidate[i], "summaries.logic_test");
          if (summaries) {
            colorScore = this.checkScoreColor(summaries.overall);
            const objects = {
              name: candidate[i].display_name,
              percentageOfBar: summaries.overall,
              value: summaries.overall,
              color: colorScore,
            };
            itemsSummarycandidate.push(objects);
          }
        }
      }
      return itemsSummarycandidate;
    },
    logicTestDataDetail() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      let summaries = [];
      const result: any = {};
      let total = 0;
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          summaries = _.get(candidate[i], "summaries.logic_test.candidate_avg");
          if (!summaries) continue;
          for (const j in summaries) {
            if (!result[j]) {
              result[j] = {
                score: 0,
              };
            }
            result[j].score += Number(summaries[j] || 0);
          }
          total++;
        }
      }
      for (const i in result) {
        result[i].score = total ? result[i].score / total : 0;
        result[i].color = this.checkScoreColor(result[i].score);
      }
      itemsSummarycandidate = [
        {
          id: "pattern_recognition",
          label: helpers.t("assessmentResults.Pattern_Recognition"),
          value: Math.round(Number(result.pattern_recognition.score)) || 0,
          color: result.pattern_recognition.color,
          tooltip: "",
        },
        {
          id: "logical_reasoning",
          label: helpers.t("assessmentResults.Logical_Reasoning"),
          value: Math.round(Number(result.logical_reasoning.score)) || 0,
          color: result.logical_reasoning.color,
          tooltip: "",
        },
        {
          id: "data_interpretation",
          label: helpers.t("assessmentResults.Data_Interpretation"),
          value: Math.round(Number(result.data_interpretation.score)) || 0,
          color: result.data_interpretation.color,
          tooltip: "",
        },
        {
          id: "problem_resolving",
          label: helpers.t("assessmentResults.Problem_Resolving"),
          value: Math.round(Number(result.problem_resolving.score)) || 0,
          color: result.problem_resolving.color,
          tooltip: "",
        },
      ];

      return itemsSummarycandidate;
    },

    acceptableBehaviorsLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          let colorScore = "";
          if (!candidate[i].summaries) continue;
          let summaries = _.get(candidate[i], "summaries.acceptable_behaviors");
          if (summaries) {
            colorScore = this.checkScoreColor(summaries.overall);
            const objects = {
              name: candidate[i].display_name,
              percentageOfBar: summaries.overall,
              value: summaries.overall,
              color: colorScore,
            };
            itemsSummarycandidate.push(objects);
          }
        }
      }
      console.log(itemsSummarycandidate, "itemsSummarycandidate");
      return itemsSummarycandidate;
    },
    acceptableBehaviorsDataDetail() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsSummarycandidate: any[] = [];
      let itemCalculator: any[] = [];
      let summaries: any;
      let result = 0;
      let colorScore = "";
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          summaries = _.get(candidate[i], "summaries.acceptable_behaviors");
          if (summaries) {
            itemCalculator.push(summaries.overall);
            result = _.sum(itemCalculator) / itemCalculator.length;
            colorScore = this.checkScoreColor(summaries.overall);
          }
        }
      }
      itemsSummarycandidate = [
        {
          label: "Scenario Alignment",
          value: Math.round(result),
          color: colorScore,
        },
      ];
      console.log(itemsSummarycandidate, "itemsSummarycandidate");
      return itemsSummarycandidate;
    },
    inviteLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsInviteData = 0;
      let percentItem = "";
      for (const i in candidate) {
        if (candidate[i].status == "INVITED") {
          itemsInviteData++;
        }
      }
      if (itemsInviteData != 0) {
        const percentage = candidate.length
          ? Math.round((itemsInviteData / candidate.length) * 100)
          : 0;
        percentItem = "(" + percentage + "%)";
      }
      return itemsInviteData + " " + percentItem;
    },
    startedLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsInviteData = 0;
      let percentItem = "";
      for (const i in candidate) {
        if (candidate[i].status == "TODO") {
          itemsInviteData++;
        }
      }
      if (itemsInviteData != 0) {
        const percentage = candidate.length
          ? Math.round((itemsInviteData / candidate.length) * 100)
          : 0;
        percentItem = "(" + percentage + "%)";
      }
      return itemsInviteData + " " + percentItem;
    },
    incompleteLineData() {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsInviteData = 0;
      let percentItem = "";
      for (const i in candidate) {
        if (candidate[i].status == "IN_PROGRESS") {
          itemsInviteData++;
        }
      }
      if (itemsInviteData != 0) {
        const percentage = candidate.length
          ? Math.round((itemsInviteData / candidate.length) * 100)
          : 0;
        percentItem = "(" + percentage + "%)";
      }
      return itemsInviteData + " " + percentItem;
    },
    completeLineData() {
      const candidate: any[] = this.$store.getters["assessment/candidates"];
      let itemsInviteData = 0;
      let percentItem = "";
      for (const i in candidate) {
        if (candidate[i].status == "COMPLETED") {
          itemsInviteData++;
        }
      }
      if (itemsInviteData != 0) {
        const percentage = candidate.length
          ? Math.round((itemsInviteData / candidate.length) * 100)
          : 0;
        percentItem = "(" + percentage + "%)";
      }
      return itemsInviteData + " " + percentItem;
    },
    hasValueAndTraitsLineData() {
      if (!this.valueAndTraitsLineData.length) {
        return false;
      }
      return true;
    },
    hasCultureProfileLineData() {
      if (!this.cultureProfileLineData.length) {
        return false;
      }
      return true;
    },
    hasHumanSkillsLineData() {
      if (!this.humanSkillsLineData.length) {
        return false;
      }
      return true;
    },
    hasLogicTestLineData() {
      if (!this.logicTestLineData.length) {
        return false;
      }
      return true;
    },
    hasAcceptableBehaviorsLineData() {
      if (!this.acceptableBehaviorsLineData.length) {
        return false;
      }
      return true;
    },
    canInvite() {
      return this.$store.getters["quota/canInvite"];
    },
    canAdvancedInvite() {
      return this.$store.getters["quota/canAdvancedInvite"];
    },
    canSendAdvancedInvite(): boolean {
      return this.checkSendAdvancedInvite();
    },
    canEdit(): boolean {
      if (this.formTemplate && this.user) {
        return this.formTemplate.creator == this.user.email;
      }
      return false;
    },
  },
  watch: {
    mutipleBulkEmail(value) {
      this.cloneValidateEmail(value);
    },
    email(value) {
      this.checkingEmailStatus(value);
    },
    signupForm: {
      handler(value) {
        this.checkingValidate(value);
      },
      deep: true,
    },
  },
  async created() {
    // Get last page visited
    const lastPage = String(this.$router.options.history.state.back) || "";
    if (lastPage && !lastPage.includes("/account")) {
      this.lastPage = lastPage;
    } else {
      this.lastPage = "/";
    }
  },
  async mounted() {
    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
    this.$store.dispatch("quota/load", this.user, { root: true });

    this.checkPropsId();
    this.cloneValidateEmail("");
    this.loadCandidates();
    // set mockdata
    this.acceptableMockData = acceptableData;
    this.valueAndTraitsMockData = valueAndTraitsData;

    const user = this.$store.getters["user/user"];
    this.guidedTourCreateDone = _.get(user, "guidedTourCreateDone", false);
    this.guidedTourInviteDone = _.get(user, "guidedTourInviteDone", false);

    setTimeout(() => {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      let content = this.getDefaultInviteHtmlBody();
      if (formTemplate && formTemplate.advancedInviteEmailTemplate) {
        const advancedInviteEmailTemplate =
          formTemplate.advancedInviteEmailTemplate;
        if (advancedInviteEmailTemplate.htmlBody) {
          content = advancedInviteEmailTemplate.htmlBody;
          this.advancedForm.subject = advancedInviteEmailTemplate.subject;
          this.advancedForm.emailSender = advancedInviteEmailTemplate.sender;
        }
      }
      (this.advancedForm as any).emailBody = new Editor({
        content: content,
        extensions: [StarterKit],
      });
    }, 3000);
  },
  methods: {
    checkingEmailStatus(email: string) {
      // console.log("checkingEmailStatus", email);
      const emailValid = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (email.length > 5 && emailValid) {
        this.validateEmail = true;
      } else if (email.length > 5 && !emailValid) {
        this.validateEmail = false;
      } else {
        this.validateEmail = false;
      }
      // console.log(this.validateEmail, "this.validateEmail");
    },
    checkingValidate(value: any) {
      this.checkingEmailStatus(value.email);
      const validateForm = {
        firstname: value.firstname && value.firstname.length > 2,
        lastname: value.lastname && value.lastname.length > 2,
        companyName: value.companyName && value.companyName.length > 2,
        email: this.validateEmail,
      };
      // check validate form
      const validateFormStatus = Object.values(validateForm).every(
        (item) => item
      );
      this.validateFormStatus = validateFormStatus;
    },
    async signupContinue() {
      if (!this.validateFormStatus) {
        return false;
      }

      // validate email again (already exists in firebase?)
      // It's not user email
      //*
      const email = this.signupForm.email;
      const existingUser = await this.$store.dispatch("user/hasUser", email, {
        root: true,
      });
      if (existingUser) {
        const errorMsg = helpers.t("createAccount.email_registered", { email });
        this.emailWarningText = errorMsg;
        this.validateFormStatus = false;
        return false;
      }
      //*/

      this.emailWarningText = "";
      this.signupStep = 2;
    },
    async updateGuestUser() {
      console.log("updateGuestUser()");
      const user = this.$store.getters["user/user"];
      const email = _.get(user, "email");
      if (!email) {
        console.log("Email was not found");
        return;
      }
      const fields = ["firstname", "lastname", "companyName"];
      const userDetails: any = _.pick(this.signupForm, fields);
      userDetails.email = email;
      userDetails.emailTo = this.signupForm.email;
      userDetails.color = this.hexCode || "";

      const saveUser = async (userDetails: any) => {
        userDetails.firstName = userDetails.firstname;
        userDetails.lastName = userDetails.lastname;
        userDetails.displayName = `${userDetails.firstName} ${userDetails.lastName}`;
        delete userDetails.firstname;
        delete userDetails.lastname;
        await this.$store.dispatch("user/saveUser", userDetails, {
          root: true,
        });

        // Alias variables to this.form when it doesn't save to global variables
        _.assign(this.$store.getters["auth/user"], userDetails);
        _.assign(this.$store.getters["user/user"], userDetails);

        // saave data to local storage for reload page issue
        let userData: any = localStorage.getItem("userData");
        if (userData) {
          try {
            userData = JSON.parse(
              decodeURIComponent(escape(atob(String(userData)))) || "{}"
            );
            _.assign(userData, userDetails);
            localStorage.setItem(
              "userData",
              btoa(unescape(encodeURIComponent(JSON.stringify(userData))))
            );
          } catch (e: any) {
            console.error(e);
          }
        }

        this.doInvite();
      };

      const file = this.signupForm.companyUploadedFile;
      const dataUrl = this.signupForm.companyUploadedFileDataUrl;
      if (dataUrl && file) {
        const storage = getStorage();
        const emailKey = helpers.emailKey(email);
        if (file instanceof File) {
          const prefix = "company";
          const guid = helpers.guid();
          let filename = file.name;
          if (filename.indexOf(".") !== -1) {
            const chunks = filename.split(".");
            const ext = chunks.pop();
            filename = chunks.join(".") + "-" + guid + "." + ext;
          }
          const path = `images/users/${emailKey}/${prefix}-${filename}`;
          const storageRef = ref(storage, path);
          uploadString(storageRef, dataUrl, "data_url").then(
            async (snapshot) => {
              getDownloadURL(snapshot.ref).then(async (url) => {
                userDetails.companyLogoUrl = url;
                await saveUser(userDetails);
              });
            }
          );
        }
      } else {
        await saveUser(userDetails);
      }
    },
    async signupSendInvite() {
      await this.updateGuestUser();
      this.signupModalStatus = false;
    },
    async signupSendWithBranding() {
      await this.signupSendInvite();
    },
    handleAvatarSuccess(res: any) {
      this.signupForm.imageFile = URL.createObjectURL(res.file);

      const reader = new FileReader();
      reader.addEventListener("load", (/*event: ProgressEvent*/) => {
        this.signupForm.companyUploadedFile = res.file;
        this.signupForm.companyUploadedFileDataUrl = String(reader.result);
      });
      reader.readAsDataURL(res.file);
    },
    handleFileUpload(event: any) {
      const file = event.target.files[0];
      this.selectedFile = file;

      if (file && file.type.includes("image")) {
        this.previewUrl = URL.createObjectURL(file);
        this.handleAvatarSuccess({ file });
      } else {
        this.previewUrl = "";
      }
    },
    onColorChange(val: any) {
      this.hexCode = val.hex;
    },
    isCreatedByGuidedTour(data: any): boolean {
      return _.get(data, "is_created_by_guided_tour", false);
    },
    isAllowedToShowInvitePopover(data: any) {
      let allowed = this.guidedTourCreateDone && !this.guidedTourInviteDone;
      if (allowed) {
        allowed = this.isCreatedByGuidedTour(data);
      }
      return allowed;
    },
    async finishGuidedTourInvite() {
      const user = this.$store.getters["user/user"];
      user.guidedTourInviteDone = true;
      await this.$store.dispatch("user/saveUser", user, { root: true });
      this.guidedTourInviteDone = true;
    },
    handleClickOnCultureProfileTab(value: any) {
      for (const i in this.cultureProfileDataDetail) {
        if (this.cultureProfileDataDetail[i].label == value) {
          this.openChartcultureModalDetail(this.cultureProfileDataDetail[i]);
        }
      }
    },
    handleClickOnHumanSkillsTab(value: any) {
      for (const i in this.humanSkillsDataDetail) {
        if (this.humanSkillsDataDetail[i].label == value) {
          this.openChartHumanSkillsModalDetail(this.humanSkillsDataDetail[i]);
        }
      }
    },
    handleClickOnLogicTestTab(value: any) {
      for (const i in this.logicTestDataDetail) {
        if (this.logicTestDataDetail[i].label == value) {
          this.openChartLogicTestModalDetail(this.logicTestDataDetail[i]);
        }
      }
    },
    progressData(status: string): number {
      const candidate = this.$store.getters["assessment/candidates"];
      let itemsInviteData = 0;
      let percentItem = 0;
      for (const i in candidate) {
        if (candidate[i].status == status) {
          itemsInviteData++;
        }
      }
      percentItem = (itemsInviteData / candidate.length) * 100;
      return percentItem ? percentItem : 0;
    },
    backLastPage() {
      this.$router.push(this.lastPage);
    },
    onEnter() {
      this.mutipleBulkEmail = this.mutipleBulkEmail + " " + this.inputBulkEmail;
      this.inputBulkEmail = "";
    },
    removeEmailBulked(index: number, email: string) {
      this.mutipleBulkValidteEmail.splice(index, 1);
      this.mutipleBulkEmail = this.mutipleBulkEmail.replace(email, "");
    },
    checkCanInvite() {
      if (!this.canInvite) {
        // show error message can't invite
        ElMessage.error((this as any).$t("popup.message.message_9e"));
        return false;
      }
      return true;
    },
    openAdvancedInviteSenderModal() {
      if (!this.canAdvancedInvite) {
        // ElMessage.error((this as any).$t("popup.message.message_9e"));
        return false;
      }
      this.advancedInviteStatus = true;
    },
    closeAdvancedInviteSenderModal() {
      this.advancedInviteStatus = false;
    },
    getDefaultInviteHtmlBody() {
      // @see cloud function: sendEmailToCandidateInviteWeb
      // console.log("getInviteHtmlBody()");
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const user = this.$store.getters["user/user"] || {};
      // console.log(formTemplate, "formTemplate");

      // {==SECTIONS_TO_SETUP==}
      const sections = formTemplate.sections || [];
      sections.sort((a: string, b: string) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        const indexMap = {
          values_and_traits: 1,
          oaic_archetypes: 2,
          human_skills: 3,
          acceptable_behaviors: 4,
          work_style_identifier: 5,
        };
        type keys = keyof typeof indexMap;
        const keyA = a as keys;
        const keyB = b as keys;
        const $a = indexMap[keyA];
        const $b = indexMap[keyB];
        return $a - $b;
      });
      // console.log(sections, "sections");

      let SECTIONS_TO_SETUP = "<ol>";
      for (let i = 0; i < sections.length; i++) {
        const sectionName = sections[i].toLowerCase();
        switch (sectionName) {
          case "values_and_traits":
            SECTIONS_TO_SETUP +=
              // "<p>" + (i + 1).toString() + ". Values & Traits</p>";
              "<li>Values & Traits</li>";
            break;
          case "oaic_archetypes":
            SECTIONS_TO_SETUP +=
              // "<p>" + (i + 1).toString() + ". Culture Profile</p>";
              "<li>Culture Profile</li>";
            break;
          case "human_skills":
            SECTIONS_TO_SETUP +=
              // "<p>" + (i + 1).toString() + ". Human Skills</p>";
              "<li>Human Skills</li>";
            break;
          case "acceptable_behaviors":
            SECTIONS_TO_SETUP +=
              // "<p>" + (i + 1).toString() + ". Acceptable Behaviors</p>";
              "<li>Acceptable Behaviors</li>";
            break;
          case "work_style_identifier":
            SECTIONS_TO_SETUP +=
              // "<p>" + (i + 1).toString() + ". Work Style Identifier</p>";
              "<li>Work Style Identifier</li>";
            break;
        }
      }
      SECTIONS_TO_SETUP += "</ol>";

      let sectionTime = 5;
      const extraTime: number = formTemplate.extra_time || 0;
      sectionTime += extraTime;

      let TOTAL_MINUTES = sections.length * sectionTime;
      // console.log(TOTAL_MINUTES, "TOTAL_MINUTES");

      // Human Skills use 10 minutes NOT 5 minutes
      if (_.indexOf(sections, "human_skills") !== -1) {
        TOTAL_MINUTES += 5;
      }

      const htmlBody = `
      <h2>Hi $RECIPIENT_FIRST_NAME</h2>
      <p>$SENDER_FIRST_NAME $SENDER_LAST_NAME from ${user.companyName} has invited you to take a Culture Assessment. This assessment has the following sections:</p>

      ${SECTIONS_TO_SETUP}

      <p>We recommend that you take this assessment when you have ${TOTAL_MINUTES} minutes.</p>
      <br />
      <p>$BUTTON_TO_ASSESSMENT</p>
      <br />
      <p>If you have any questions or concerns, please feel free to reach out to us at support@happily.ai anytime!</p>
      <p>Happily yours,</p>
      <p>The MyCulture Team</p>
`;

      return htmlBody;
    },

    checkScoreColor(data: any) {
      let color = "";
      if (data < 60) {
        color = "red";
      } else if (data < 80) {
        color = "yellow";
      } else if (data >= 80) {
        color = "green";
      }
      // console.log(`data: ${data}, color: ${color}`);
      return color;
    },
    cloneValidateEmail(emailArr: any) {
      const comma = emailArr.split(/, | /);
      this.mutipleBulkValidteEmail = [];
      if (comma.length > 0) {
        for (let i = 0; i < comma.length; i++) {
          if (comma[i].trim()) {
            const emailValidate = comma[i]
              .trim()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
            if (emailValidate) {
              this.mutipleBulkValidteEmail.push(comma[i].trim());
            }
          }
        }
      }
      this.mutipleBulkValidteEmail = _.uniq(this.mutipleBulkValidteEmail);
    },
    async loadCandidates() {
      const formId = this.$router.currentRoute.value.query.id;
      await this.$store.dispatch(
        "assessment/getCandidates",
        { formId: formId },
        { root: true }
      );

      // Set created by
      const formTemplate = this.$store.getters["assessment/formTemplate"];
      if (formTemplate) {
        const creator = await this.$store.dispatch(
          "user/getUser",
          formTemplate.creator,
          { root: true }
        );
        if (creator) {
          let displayName = creator.displayName;
          const chunks = displayName.split(" ");
          if (chunks.length > 1) {
            displayName = chunks[0] + " " + chunks[1][0];
          }
          this.createdBy = displayName;
        }
        // this.setHight();
      }
    },
    editForm() {
      if (!this.canEdit) {
        console.log("Can't edit");
        return;
      }
      const routeId = this.formTemplate.routeId;
      this.$router.replace(`/assessment/edit/${routeId}`);
    },
    async handleCommand(command: string, row: any) {
      switch (command) {
        case "edit":
          break;
        case "delete":
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_3.question"),
            (this as any).$t("popup.question.question_box_3.title"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              await this.$store.dispatch("assessment/deleteCandidate", row, {
                root: true,
              });
              this.loadCandidates();
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        case "clone":
          break;
      }
    },
    assessmentsCandidate(row: any) {
      if (row.status != "COMPLETED") return;
      const id = row._id;
      this.$router.push({
        name: "AssessmentsCandidate",
        query: {
          as_id: this.assessmentsID,
          u_id: btoa(encodeURIComponent(id)),
        },
      });
    },
    checkPropsId() {
      const id = this.$router.currentRoute.value.query.id;
      if (id) {
        this.assessmentsID = id + "";
      } else {
        ElMessage({
          message: (this as any).$t("popup.message.message_12"),
          type: "warning",
        });
        setTimeout(() => {
          this.$router.push({ name: "Assessments" });
        }, 1000);
      }
    },
    copyLink() {
      if (!this.canInvite) {
        // show error message can't invite
        ElMessage.error((this as any).$t("popup.message.message_9e"));
        return false;
      }
      if (!this.publicLink) return;
      ElMessage({
        message: (this as any).$t("popup.message.message_8"),
        type: "success",
      });
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(this.publicLink);
    },
    resetInvitationForm() {
      this.form.email = "";
      this.form.firstname = "";
      this.form.lastname = "";
    },
    async _invite(option: { htmlBody?: string } = {}) {
      if (!this.formTemplate) {
        console.log("this.formTemplate was not found.");
        return;
      }
      /* eslint-disable */
      const email = this.form.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      /* eslint-enable */
      let validate = true;

      // HTML Body from advanced invitation
      if (!option.htmlBody) {
        this.formValidate.email = false;
        if (!email) {
          this.formValidate.email = true;
          validate = false;
        }
      }
      if (validate) {
        const formId = this.$router.currentRoute.value.query.id;

        // @todo make publicLink for checking link from email and init data
        const formDetailsDto: any = _.merge(
          {
            formId: formId,
            publicUrl: this.publicLink,
            email: this.form.email,
            firstname: this.form.firstname,
            lastname: this.form.lastname,
            sections: Object.values(_.get(this.formTemplate, "sections", [])),
          },
          option
        );

        await this.$store.dispatch(
          "assessmentTemplate/invite",
          formDetailsDto,
          {
            root: true,
          }
        );
      }
    },
    async doInvite(email?: string) {
      if (!email) {
        email = this.form.email;
      }
      if (!this.checkCanInvite()) return;

      /* eslint-disable */
      const matches = email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!matches) {
        return false;
      }

      this.inviteButtonLoading = true;
      await this._invite();
      await this.finishGuidedTourInvite();
      this.inviteButtonLoading = false;
      ElMessage({
        message:
          (this as any).$t("popup.message.message_13_1") +
          // email +
          this.form.email +
          (this as any).$t("popup.message.message_13_2"),
        type: "success",
      });
      this.resetInvitationForm();
      this.loadCandidates();
    },
    async invite() {
      if (!this.checkCanInvite()) return;

      // @todo do not ask if data are saved
        
      if (this.isGuest) {
        const email = this.form.email;
        /* eslint-disable */
        const matches = email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!matches) {
          return false;
        }

        this.signupStep = 1;
        this.signupModalStatus = true;
        return;
      }
      this.doInvite();
    },
    format(datetime: string) {
      return helpers.dateFormat(datetime);
    },
    status(status: string) {
      return helpers.workflowStatus(status);
    },
    onClickRating(item: any) {
      // don't neccessary to await
      this.$store.commit("assessment/ignoreLoading", true);
      this.$store.dispatch("assessment/saveRating", item, { root: true });
      this.$store.commit("assessment/ignoreLoading", false);
    },
    // setHight() {
    //   this.inviteExpendHeight = this.$refs.invite_container_ref
    //     ? (this.$refs.invite_container_ref as any).clientHeight + 30
    //     : 0;
    //   this.anlyticsExpendHeight = this.$refs.analytics_container_ref
    //     ? (this.$refs.analytics_container_ref as any).clientHeight + 30
    //     : 0;
    // },
    async openValuesAndTraitsModalDetail(item?: any) {
      if (this.analyticsLoadingStatus) return;
      this.valueAndTraitsModalStatus = true;
      if (item && item.label) {
        await this.loadAnalytics();
        this.valueAndTraitsTapSelected = item.label;
      }
    },
    async openChartcultureModalDetail(item?: any) {
      if (this.analyticsLoadingStatus) return;
      // is called by setTimeout (sometime)
      if (!this.cultureProfileModalStatus) {
        this.cultureProfileModalStatus = !this.cultureProfileModalStatus;
      }
      if (item && item.label) {
        this.cultureProfileTabSelected = item.label;
        this.cultureProfileSelected = item;
        if (item.tooltip) {
          this.tooltipOncultureStatus = false;
          this.tooltipOncultureLabelPrefix =
            item.tooltip.tooltipOncultureLabelPrefix + " ";
          this.tooltipOncultureLabel = item.tooltip.tooltipOncultureLabel;
        }
      }
      await this.loadAnalytics();
      if (this.cultureProfileModalStatus) {
        if (!this.culturePreferenceRenderChart) {
          this.chartculturePreference();
        } else {
          setTimeout(() => {
            console.log("setTimeout(300)");
            this.openChartcultureModalDetail();
          }, 300);
        }
      }
    },
    async openChartHumanSkillsModalDetail(item?: any) {
      if (this.analyticsLoadingStatus) return;
      // is called by setTimeout (sometime)
      if (!this.humanSkillsModalStatus) {
        this.humanSkillsModalStatus = !this.humanSkillsModalStatus;
      }
      if (item && item.label) {
        this.humanSkillsTabSelected = item.label;
        this.humanSkillsSelected = item;
        if (item.tooltip) {
          this.tooltipOncultureStatus = false;
          this.tooltipOncultureLabelPrefix =
            item.tooltip.tooltipOncultureLabelPrefix + " ";
          this.tooltipOncultureLabel = item.tooltip.tooltipOncultureLabel;
        }
      }
      await this.loadAnalytics();
      if (this.humanSkillsModalStatus) {
        if (!this.humanSkillsRenderChart) {
          this.chartHumanSkillsPreference();
        } else {
          setTimeout(() => {
            console.log("setTimeout(310)");
            this.openChartHumanSkillsModalDetail();
          }, 310);
        }
      }
    },
    async openChartLogicTestModalDetail(item?: any) {
      if (this.analyticsLoadingStatus) return;
      // is called by setTimeout (sometime)
      if (!this.logicTestModalStatus) {
        this.logicTestModalStatus = !this.logicTestModalStatus;
      }
      if (item && item.label) {
        this.logicTestTabSelected = item.label;
        this.logicTestSelected = item;
        if (item.tooltip) {
          this.tooltipOncultureStatus = false;
          this.tooltipOncultureLabelPrefix =
            item.tooltip.tooltipOncultureLabelPrefix + " ";
          this.tooltipOncultureLabel = item.tooltip.tooltipOncultureLabel;
        }
      }
      await this.loadAnalytics();
      if (this.logicTestModalStatus) {
        if (!this.logicTestRenderChart) {
          this.chartLogicTestPreference();
        } else {
          setTimeout(() => {
            console.log("setTimeout(310)");
            this.openChartLogicTestModalDetail();
          }, 310);
        }
      }
    },
    async openAcceptableBehaviorsDataDetail() {
      if (this.analyticsLoadingStatus) return;
      this.acceptableBehaviorsModalStatus = true;
      await this.loadAnalytics();
    },
    chartculturePreference() {
      this.culturePreferenceRenderChart = true;
      const chartContent = this.$refs.culture_preference_chart;

      if (this.radarChartVal && Object.keys(this.radarChartVal).length) {
        (this.radarChartVal as any).destroy();
      }
      if (chartContent) {
        const ctx = (chartContent as any).getContext("2d");
        const options = {
          elements: {
            line: {
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              grid: {
                display: false,
              },
              min: 0,
              ticks: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
                beginAtZero: false,
                stepSize: 10,
                callback: function () {
                  return "";
                },
              },
              pointLabels: {
                color: "#000",
                fontSize: 14,
                font: {
                  size: 14,
                  weight: "700",
                },
              },
              gridLines: {
                lineWidth: 2,
                color: "lightgreen",
                borderDash: (context: any) =>
                  context.index == 6 ? [] : [6, 4],
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              intersect: false,
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              titleColor: '#1B1C1E',
              titleFont: {
                size: 14,
                weight: '700'
              },
              padding: 12,
              boxPadding: 6,
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 1,
              displayColors: true, // Show color indicator
              callbacks: {
                title: function () {
                  return ''; // Empty title
                },
                label: function (tooltipItems) {
                  return tooltipItems.dataset.label; // Show name as label instead
                }
              },
              // Custom tooltip box styling
              bodyColor: '#1B1C1E',  // Same color as before
              bodyFont: {
                size: 14,
                weight: '700'
              },
              usePointStyle: false, // Use rectangle style for color indicator
              boxWidth: 12,  // Width of color indicator
              boxHeight: 2   // Height of color indicator to make it look like a line
            },
          },
        };
        // const user = [
        //   {
        //     name: "jack Smith",
        //     data: [8, 11, 12, 9],
        //     color: "#30CCB4",
        //   },
        //   {
        //     name: "Allen Key",
        //     data: [8, 11, 12, 9],
        //     color: "#9CCC30",
        //   },
        //   {
        //     name: "Barbra Longely",
        //     data: [8, 11, 12, 9],
        //     color: "#2AEAF2",
        //   },
        //   {
        //     name: "Sam Lee",
        //     data: [8, 11, 12, 9],
        //     color: "#C452F7",
        //   },
        // ];

        const daatsets = [];
        // daatsets.push({
        //   label: "Company Name",
        //   data: this.radarChartCompanyData,
        //   pointStyle: "line",
        //   borderColor: this.companyColor,
        //   borderDash: [3, 1],
        // });
        for (let i = 0; i < this.radarChartCandidateData.length; i++) {
          let itemSelceted = true;
          const fillteredID = this.culturePreferenceRenderChartFilter;
          for (let index2 = 0; index2 < fillteredID.length; index2++) {
            if (fillteredID[index2] == this.radarChartCandidateData[i].id) {
              itemSelceted = false;
            }
          }
          if (itemSelceted) {
            let item = {
              label: this.radarChartCandidateData[i].name,
              data: this.radarChartCandidateData[i].data,
              pointStyle: "line",
              borderColor: this.radarChartCandidateData[i].color,
              score: this.radarChartCandidateData[i].score,
            };
            if (i == 0) {
              item = { ...item, ...{ borderDash: [3, 1] } };
            }
            daatsets.push(item);
          }
        }
        // this.radarChartCandidateData.shift();

        this.radarChartVal = new Chart(ctx, {
          type: "radar",
          data: {
            labels: [
              " ",
              " ",
              " ",
              " ",
              // "Collaborate people-oriented",
              // "Create dynamic & entrepreneurial",
              // "Complete result-oriented",
              // "Control process-oriented",
            ],
            datasets: daatsets,
          },
          options: options,
        });
      } else if (this.cultureProfileModalStatus) {
        setTimeout(() => {
          this.chartculturePreference();
        }, 150);
      }
      setTimeout(() => {
        this.culturePreferenceRenderChart = false;
      }, 1000);
    },
    chartHumanSkillsPreference() {
      this.humanSkillsRenderChart = true;
      const chartContent = this.$refs.human_skills_chart;

      if (
        this.humanSkillsRadarChartVal &&
        Object.keys(this.humanSkillsRadarChartVal).length
      ) {
        (this.humanSkillsRadarChartVal as any).destroy();
      }
      if (chartContent) {
        const ctx = (chartContent as any).getContext("2d");
        const options = {
          elements: {
            line: {
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              grid: {
                display: false,
              },
              min: 0,
              ticks: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
                beginAtZero: false,
                stepSize: 10,
                callback: function () {
                  return "";
                },
              },
              pointLabels: {
                color: "#000",
                fontSize: 14,
                font: {
                  size: 14,
                  weight: "700",
                },
              },
              gridLines: {
                lineWidth: 2,
                color: "lightgreen",
                borderDash: (context: any) =>
                  context.index == 6 ? [] : [6, 4],
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        };
        const daatsets = [];
        for (
          let i = 0;
          i < this.radarChartHumanSkillsCandidateData.length;
          i++
        ) {
          let itemSelceted = true;
          const fillteredID = this.humanSkillsRenderChartFilter;
          for (let index2 = 0; index2 < fillteredID.length; index2++) {
            if (
              fillteredID[index2] ==
              this.radarChartHumanSkillsCandidateData[i].id
            ) {
              itemSelceted = false;
            }
          }
          if (itemSelceted) {
            let item = {
              label: this.radarChartHumanSkillsCandidateData[i].name,
              data: this.radarChartHumanSkillsCandidateData[i].data,
              pointStyle: "line",
              borderColor: this.radarChartHumanSkillsCandidateData[i].color,
              score: this.radarChartHumanSkillsCandidateData[i].score,
            };
            if (i == 0) {
              item = { ...item, ...{ borderDash: [3, 1] } };
            }
            daatsets.push(item);
          }


        }
        // this.radarChartCandidateData.shift();

        this.humanSkillsRadarChartVal = new Chart(ctx, {
          type: "radar",
          data: {
            labels: [
              " ",
              " ",
              " ",
              " ",
              // "Collaborate people-oriented",
              // "Create dynamic & entrepreneurial",
              // "Complete result-oriented",
              // "Control process-oriented",
            ],
            datasets: daatsets,
          },
          options: options,
        });
      } else if (this.humanSkillsModalStatus) {
        setTimeout(() => {
          this.chartHumanSkillsPreference();
        }, 150);
      }
      setTimeout(() => {
        this.humanSkillsRenderChart = false;
      }, 1000);
    },
    chartLogicTestPreference() {
      this.logicTestRenderChart = true;
      const chartContent = this.$refs.logic_test_chart;

      if (
        this.logicTestRadarChartVal &&
        Object.keys(this.logicTestRadarChartVal).length
      ) {
        (this.logicTestRadarChartVal as any).destroy();
      }
      if (chartContent) {
        const ctx = (chartContent as any).getContext("2d");
        const options = {
          elements: {
            line: {
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              grid: {
                display: false,
              },
              min: 0,
              ticks: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
                beginAtZero: false,
                stepSize: 10,
                callback: function () {
                  return "";
                },
              },
              pointLabels: {
                color: "#000",
                fontSize: 14,
                font: {
                  size: 14,
                  weight: "700",
                },
              },
              gridLines: {
                lineWidth: 2,
                color: "lightgreen",
                borderDash: (context: any) =>
                  context.index == 6 ? [] : [6, 4],
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        };
        const daatsets = [];
        for (let i = 0; i < this.radarChartLogicTestCandidateData.length; i++) {
          let itemSelceted = true;
          const fillteredID = this.logicTestRenderChartFilter;
          for (let index2 = 0; index2 < fillteredID.length; index2++) {
            if (
              fillteredID[index2] ==
              this.radarChartLogicTestCandidateData[i].id
            ) {
              itemSelceted = false;
            }
          }
          if (itemSelceted) {
            let item = {
              label: this.radarChartLogicTestCandidateData[i].name,
              data: this.radarChartLogicTestCandidateData[i].data,
              pointStyle: "line",
              borderColor: this.radarChartLogicTestCandidateData[i].color,
              score: this.radarChartLogicTestCandidateData[i].score,
            };
            console.log('item', item);

            console.log('i', i);

            if (i == 0) {
              item = { ...item, ...{ borderDash: [3, 1] } };
            }
            daatsets.push(item);
          }
        }
        console.log('daatsets', daatsets);

        // this.radarChartCandidateData.shift();

        this.logicTestRadarChartVal = new Chart(ctx, {
          type: "radar",
          data: {
            labels: [
              " ",
              " ",
              " ",
              " ",
              // "Collaborate people-oriented",
              // "Create dynamic & entrepreneurial",
              // "Complete result-oriented",
              // "Control process-oriented",
            ],
            datasets: daatsets,
          },
          options: options,
        });
      } else if (this.logicTestModalStatus) {
        setTimeout(() => {
          this.chartLogicTestPreference();
        }, 150);
      }
      setTimeout(() => {
        this.logicTestRenderChart = false;
      }, 1000);
    },
    async loadAnalytics(): Promise<any> {
      if (!this.analyticsLoadedStatus) {
        this.analyticsLoadingStatus = true;
        const locale = (this as any).$i18n.getLocale(0) || "en";
        await this.$store.dispatch(
          "assessment/getAnalytics",
          {
            _id: this.assessmentsID,
            locale: locale,
          },
          { root: true }
        );
        this.analyticsLoadingStatus = false;
        this.analyticsLoadedStatus = true;
      }
    },
    actionOnCandidate(value: any) {
      const id = value.id || "";
      if (this.culturePreferenceRenderChartFilter.includes(id)) {
        this.culturePreferenceRenderChartFilter =
          this.culturePreferenceRenderChartFilter.filter((word) => word != id);
      } else {
        this.culturePreferenceRenderChartFilter.push(id);
      }
      if (!this.culturePreferenceRenderChart) {
        if (this.culturePreferenceRenderChartValue > 0) {
          this.culturePreferenceRenderChartValue--;
        }
        this.chartculturePreference();
        setTimeout(() => {
          this.culturePreferenceRenderChartValue = 1;
        }, 1200);
      } else {
        this.culturePreferenceRenderChartValue++;
        setTimeout(() => {
          if (this.culturePreferenceRenderChartValue > 1) {
            this.actionOnCandidate(value);
          }
        }, 400 * this.culturePreferenceRenderChartValue);
      }
    },
    candidateActiveClassOnCulturePreference(id: string) {
      let itemSelceted = true;
      const fillteredID = this.culturePreferenceRenderChartFilter;
      for (let i = 0; i < fillteredID.length; i++) {
        if (fillteredID[i] == id) {
          itemSelceted = false;
        }
      }
      if (itemSelceted) {
        return "";
      } else {
        return "hide";
      }
    },
    candidateActiveClassOnHumanSkillsPreference(id: string) {
      let itemSelceted = true;
      const fillteredID = this.humanSkillsRenderChartFilter;
      for (let i = 0; i < fillteredID.length; i++) {
        if (fillteredID[i] == id) {
          itemSelceted = false;
        }
      }
      if (itemSelceted) {
        return "";
      } else {
        return "hide";
      }
    },
    actionOnHumanSkillsCandidate(value: any) {
      const id = value.id || "";
      if (this.humanSkillsRenderChartFilter.includes(id)) {
        this.humanSkillsRenderChartFilter =
          this.humanSkillsRenderChartFilter.filter((word) => word != id);
      } else {
        this.humanSkillsRenderChartFilter.push(id);
      }
      if (!this.humanSkillsRenderChart) {
        if (this.humanSkillsRenderChartValue > 0) {
          this.humanSkillsRenderChartValue--;
        }
        this.chartHumanSkillsPreference();
        setTimeout(() => {
          this.humanSkillsRenderChartValue = 1;
        }, 1200);
      } else {
        this.humanSkillsRenderChartValue++;
        setTimeout(() => {
          if (this.humanSkillsRenderChartValue > 1) {
            this.actionOnCandidate(value);
          }
        }, 400 * this.humanSkillsRenderChartValue);
      }
    },
    candidateActiveClassOnLogicTestPreference(id: string) {
      let itemSelceted = true;
      const fillteredID = this.logicTestRenderChartFilter;
      for (let i = 0; i < fillteredID.length; i++) {
        if (fillteredID[i] == id) {
          itemSelceted = false;
        }
      }
      if (itemSelceted) {
        return "";
      } else {
        return "hide";
      }
    },
    actionOnLogicTestCandidate(value: any) {
      const id = value.id || "";
      if (this.logicTestRenderChartFilter.includes(id)) {
        this.logicTestRenderChartFilter =
          this.logicTestRenderChartFilter.filter((word) => word != id);
      } else {
        this.logicTestRenderChartFilter.push(id);
      }
      if (!this.logicTestRenderChart) {
        if (this.logicTestRenderChartValue > 0) {
          this.logicTestRenderChartValue--;
        }
        this.chartLogicTestPreference();
        setTimeout(() => {
          this.logicTestRenderChartValue = 1;
        }, 1200);
      } else {
        this.logicTestRenderChartValue++;
        setTimeout(() => {
          if (this.logicTestRenderChartValue > 1) {
            this.actionOnCandidate(value);
          }
        }, 400 * this.logicTestRenderChartValue);
      }
    },
    checkSendAdvancedInvite(doNotCheckRecipients = false): boolean {
      if (!doNotCheckRecipients) {
        const recipients = _.clone(this.advancedForm.recipients || []);
        let notFoundEmail = true;
        for (const i in recipients) {
          const recipient = recipients[i];
          if (!recipient) continue;
          const email = recipient.email || "";
          if (
            email.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ) {
            notFoundEmail = false;
          }
        }
        if (notFoundEmail) {
          console.log("notFoundEmail");
          return false;
        }
      }

      const subject = _.trim(this.advancedForm.subject || "");
      if (!subject.length) {
        console.log("!subject.length");
        return false;
      }

      if (this.advancedForm.emailBody) {
        const htmlBody = _.trim(
          (this.advancedForm.emailBody as any).getHTML() || ""
        );
        const removeTags = (str: string) => {
          if (str === null || str === "") return false;
          else str = str.toString();

          // Regular expression to identify HTML tags in
          // the input string. Replacing the identified
          // HTML tag with a null string.
          return str.replace(/(<([^>]+)>)/gi, "");
        };
        const htmlBodyCheck = String(removeTags(htmlBody));
        if (!htmlBodyCheck.length) {
          console.log("!htmlBody.length");
          return false;
        }

        if (!htmlBody.match(/\$BUTTON_TO_ASSESSMENT([^a-z])/gi)) {
          console.log("!$BUTTON_TO_ASSESSMENT");
          return false;
        }
      }

      return true;
    },
    async sendAdvancedInvite() {
      // console.log("sendAdvancedInvite()");
      // if (this.$store) return;
      if (!this.checkSendAdvancedInvite()) return false;
      this.removeEmptyValues();
      // console.log("sendAdvancedInvite", this.advancedForm);

      // @todo: validate recipients
      //        First name and Last name are optional but a valid email address is required for each row
      // @see https://trello.com/c/KbKmF0FO/1662-invites-as-a-company-i-want-to-customize-the-invitation-email-so-that-i-can-share-assessments-with-a-personal-welcome-message-wi
      const recipients = _.clone(this.advancedForm.recipients || []);
      if (recipients.length) {
        const option = {
          subject: this.advancedForm.subject,
          sender: this.advancedForm.emailSender,
          htmlBody: (this.advancedForm.emailBody as any).getHTML(),
          recipients: recipients,
        };
        this.inviteBulkButtonLoading = true;
        await this._invite(option);
        this.inviteBulkButtonLoading = false;

        ElMessage({
          message: "Invite email has been sent to candidates",
          type: "success",
        });

        // After call AIP add empty row for next invite
        if (this.advancedForm.recipients.length < 100) {
          this.advancedForm.recipients.push({
            firstname: "",
            lastname: "",
            email: "",
          });
        }

        this.finishGuidedTourInvite();

        this.closeAdvancedInviteSenderModal();
        this.loadCandidates();

        // reset recipients
        for (const i in this.advancedForm.recipients) {
          const recipient = recipients[i];
          if (recipient) {
            recipient.firstname = "";
            recipient.lastname = "";
            recipient.email = "";
          }
        }
      }
    },
    async sendTestEmail() {
      if (!this.checkSendAdvancedInvite(true)) return false;

      const user = this.$store.getters["user/user"];
      const option = {
        test: true,
        subject: this.advancedForm.subject,
        sender: this.advancedForm.emailSender,
        htmlBody: (this.advancedForm.emailBody as any).getHTML(),
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
      };
      await this._invite(option);

      ElMessage({
        message:
          // "A test email has been sent to your inbox at {admin_account_email}",
          "A test email has been sent to your inbox at " + user.email,
        type: "success",
      });
    },
    handlePaste(event: any) {
      event.preventDefault();
      const clipboardData =
        event.clipboardData || (window as any).clipboardData;
      const pastedData = clipboardData.getData("Text");
      this.processPastedData(pastedData);
    },
    processPastedData(pastedData: string) {
      const rows = pastedData.split(/\r\n|\n|\r/);
      interface Recipient {
        firstname: string;
        lastname: string;
        email: string;
      }
      let newRecipients: Recipient[] = [];

      rows.forEach((row) => {
        const columns = row.split("\t");
        if (columns.length >= 3) {
          newRecipients.push({
            firstname: columns[0],
            lastname: columns[1],
            email: columns[2],
          });
        }
      });

      if (this.advancedForm.recipients.length + newRecipients.length > 100) {
        ElMessage({
          message: (this as any).$t("popup.message.message_20w"),
          type: "warning",
        });
        return;
      }

      this.advancedForm.recipients =
        this.advancedForm.recipients.concat(newRecipients);

      this.removeEmptyValues();

      if (this.advancedForm.recipients.length < 100) {
        this.advancedForm.recipients.push({
          firstname: "",
          lastname: "",
          email: "",
        });
      }
    },
    addRecipient() {
      if (this.advancedForm.recipients.length < 100) {
        this.advancedForm.recipients.push({
          firstname: "",
          lastname: "",
          email: "",
        });
      } else {
        ElMessage({
          message: (this as any).$t("popup.message.message_20w"),
          type: "warning",
        });
      }
    },
    removeEmptyValues() {
      this.advancedForm.recipients = this.advancedForm.recipients.filter(
        (item) =>
          // @see https://trello.com/c/KbKmF0FO/1662-invites-as-a-company-i-want-to-customize-the-invitation-email-so-that-i-can-share-assessments-with-a-personal-welcome-message-wi
          // First name and Last name are optional but a valid email address is required for each row.
          //
          // item.firstname.trim() !== "" &&
          // item.lastname.trim() !== "" &&
          item.email.trim() !== ""
      );
    },
    isDuplicateEmail(email: string, index: number) {
      if (email.trim() === "" || !email) return false;
      return (
        this.advancedForm.recipients.findIndex(
          (item, idx) => item.email === email && idx !== index
        ) !== -1
      );
    },
    companyValueColorHex(label: string) {
      const color = [
        {
          value: 0,
          label: "Acceptable",
          color: "#22BC66",
        },
        {
          value: 1,
          label: "Frowned Upon",
          color: "#FFC107",
        },
        {
          value: 2,
          label: "Actively Discouraged",
          color: "#f8961e",
        },
        {
          value: 3,
          label: "Reprimanded",
          color: "#f3722c",
        },
        {
          value: 4,
          label: "Zero Tolerance Policy",
          color: "#ff4343",
        },
      ];
      const item = color.find((item) => item.label === label);
      return item ? item.color : "#1B1C1E";
    },
    getCultureDimensionLabel(type: string) {
      // Default labels if no tab selected
      if (!this.cultureProfileTabSelected) {
        switch (type) {
          case 'collaborate': return this.$t("assessmentResults.Collaborate")
          case 'control': return this.$t("assessmentResults.Control")
          case 'create': return this.$t("assessmentResults.Create")
          case 'compete': return this.$t("assessmentResults.Compete")
          default: return this.$t("assessmentResults.Collaborate")
        }
      }

      // Map tab names to dimension IDs
      const dimensionMap: { [key: string]: string } = {
        'Dominant Characteristics': 'dominant_characteristics',
        'Organizational Glue': 'organization_glue',
        'Management of Employees': 'management_of_employees',
        'Organizational Leadership': 'organizational_leadership',
        'Criteria of Success': 'criteria_of_success',
        'Strategic Emphases': 'strategic_emphases'
      }

      const dimension = dimensionMap[this.cultureProfileTabSelected]

      switch (dimension) {
        case 'dominant_characteristics':
          switch (type) {
            case 'collaborate': return this.$t("question.cultureProfile.Family_Atmosphere")
            case 'compete': return this.$t("question.cultureProfile.Results_Driven")
            case 'create': return this.$t("question.cultureProfile.Entrepreneurial_Spirit")
            case 'control': return this.$t("question.cultureProfile.Structured_Environment")
          }
          break
        case 'organization_glue':
          switch (type) {
            case 'collaborate': return this.$t("question.cultureProfile.Trust_and_Loyalty")
            case 'compete': return this.$t("question.cultureProfile.Achievement_Focus")
            case 'create': return this.$t("question.cultureProfile.Innovative_Commitment")
            case 'control': return this.$t("question.cultureProfile.Structured_Stability")
          }
        case 'management_of_employees':
          switch (type) {
            case 'collaborate': return this.$t("question.cultureProfile.Teamwork_Emphasis")
            case 'compete': return this.$t("question.cultureProfile.Competitive_Drive")
            case 'create': return this.$t("question.cultureProfile.Individual_Innovation")
            case 'control': return this.$t("question.cultureProfile.Stable_Employment")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'organizational_leadership':
          switch (type) {
            case 'collaborate': return this.$t("question.cultureProfile.Nurturing_Leadership")
            case 'compete': return this.$t("question.cultureProfile.Aggressive_Focus")
            case 'create': return this.$t("question.cultureProfile.Innovative_Pioneers")
            case 'control': return this.$t("question.cultureProfile.Efficient_Coordination")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'criteria_of_success':
          switch (type) {
            case 'collaborate': return this.$t("question.cultureProfile.People_Centric_Success")
            case 'compete': return this.$t("question.cultureProfile.Marketplace_Dominance")
            case 'create': return this.$t("question.cultureProfile.Innovative_Leadership")
            case 'control': return this.$t("question.cultureProfile.Operational_Efficiency")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'strategic_emphases':
          switch (type) {
            case 'collaborate': return this.$t("question.cultureProfile.Human_Development")
            case 'compete': return this.$t("question.cultureProfile.Competitive_Achievement")
            case 'create': return this.$t("question.cultureProfile.Opportunity_Exploration")
            case 'control': return this.$t("question.cultureProfile.Operational_Stability")
            default: return this.$t("assessmentResults.Collaborate")
          }
          break
      }

      // Fallback to default labels
      return this.$t(`assessmentResults.${type.charAt(0).toUpperCase() + type.slice(1)}`)
    },
  },
  beforeUnmount() {
    if (this.advancedForm.emailBody) {
      (this.advancedForm as any).emailBody.destroy();
    }
  },
});
